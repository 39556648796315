import styled from '@emotion/styled';

import CustomTheme from 'interfaces/CustomTheme';
import { SPACER } from 'consts';

export const Container = styled.div`
  display: flex; 
  justify-content: flex-end;
  align-items: center;
`;

export const Button = styled.button<{ theme: CustomTheme }>`
  cursor: pointer;
  width: 5rem;
  border: 0;
  padding: ${SPACER.small} ${SPACER.base};
  transition: background-color .25s ease-out;

  ${({ theme }) => `
    font-family: ${theme.fonts.monospace};
    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
  `}
  
  font-size: 16px;
`;