import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';
import * as Shared from 'components/style';

export const Nav = styled.nav<{ isHome: boolean; theme: CustomTheme; }>`
  height: ${Shared.PRIMARY_NAV_HEIGHT};
  border: 1px solid transparent;
  border-bottom: 1px dashed;
  padding: 0 ${SPACER.small};

  display: grid;
  grid-template-columns: 1fr minmax(auto, 1280px) 1fr;
  align-items: center;
  z-index: ${Shared.Z_INDEX.PRIMARY_NAV};
  
  position: fixed; 
  top: 0; 
  left: 0; 
  right: 0;
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color .25s ease-out;

  ${({ isHome }) => isHome && `
    border-color: unset;
    border-bottom: 1px solid;
    grid-template-columns: 1fr 1fr; 
  `}
`;

export const BreadCrumbs = styled.div<{ theme: CustomTheme }>`
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: 16px;
  padding: 0 ${SPACER.base};

  a {
    text-decoration: none; 
  }

  a:hover {
    text-decoration: underline dashed;
	  text-decoration-skip-ink: none;
	  text-decoration-thickness: 1px;
  }
`;