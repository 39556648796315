import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';
import * as Shared from 'components/style';

export const Footer = styled.footer<{ theme: CustomTheme }>`
  border: 1px solid transparent;
  border-top: 1px dashed;
  height: ${Shared.PRIMARY_NAV_HEIGHT};
  padding: 0 ${SPACER.base};
  display: flex; 
  justify-content: space-between;
  align-items: center;

  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 20px; 
  font-style: italic;

  a {
    text-decoration: none; 
  
    &:hover {
      text-decoration: underline dashed;
      text-decoration-skip-ink: none;
      text-decoration-thickness: 1px;
    }
  }
`;